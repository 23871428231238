<template>
  <v-row>
    <v-col cols="12">
      <WeeklyReport />
    </v-col>
    <v-col cols="12">
      <UserSubjects path="workplace" />
    </v-col>
  </v-row>
</template>

<script>
import UserSubjects from "@/components/UserSubjects";
import WeeklyReport from "@/components/WeeklyReport";
export default {
  metaInfo: {
    title: "Workplace",
  },
  name: "Workplace",
  components: {
    UserSubjects,
    WeeklyReport,
  },
  async mounted() {
    await this.$store.dispatch('authModule/getUser');
    const user = this.$store.getters['authModule/getUser'];

    if (user) {
      const role = user.role;
      switch (role) {
        case 'teacher':
          this.$store.commit("subjectsModule/setSubject", false);
          await this.$store.dispatch("studentsModule/getTeacherAdvisory");
          await this.$store.dispatch("authModule/getWeeklyReport");
          break;
        case 'student':
          this.$router.push("/classroom");
          break;
        default:
          this.$router.push("/main");
          break;
      }
    } else {
      this.$router.push("/main");
    }
  },
};
</script>

<style></style>
